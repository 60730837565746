import {Teilnahme} from './teilnahme.model';
import {BaseEntity} from './baseEntity.model';
import {Produkt} from './produkt.model';
import {Kontakt} from './kontakt.model';
import {RepraesentationBedarf} from "./repraesentationBedarf.model";
import {Angebotsart} from "./angebotsart.model";
import { Repraesentationart } from './repraesentationart.model';

export class Repraesentation extends BaseEntity {
    kontaktId: number;
    kontakt: Kontakt;
    fullDescription: string;
    name: string;
    teilnahmen: Teilnahme[];
    sortiment: Produkt[];
    sortimentString: string;
    angebotsart: Angebotsart;
    repraesentationart: Repraesentationart;
    bedarfe: RepraesentationBedarf[];
    kommentar: string;
    abacusClientId: number;
    customData: object;
    laufmeterGemeindestand: number;
    tiefeGemeindestand: number;
    laufmeterAndere: number;
    tiefeAndere: number;
    laufmeter: number;
    tiefe: number;
    durchmesser: number;
    hoehe: number;
}
