import { Component, Input, OnInit } from '@angular/core';
import {getTeilnahmeStatusModel} from '../../models/teilnahme.model';

@Component({
    selector: 'app-teilnahme-status',
    templateUrl: './teilnahme-status.component.html',
    styleUrls: ['./teilnahme-status.component.scss']
})
export class TeilnahmeStatusComponent implements OnInit {
    @Input() statusBefore: number;
    @Input() statusNow: number;
    teilnahmeStatusModel: any = getTeilnahmeStatusModel();

    constructor() {
    }

    ngOnInit() {
    }

}
