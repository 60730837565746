import {Directive, EventEmitter, HostListener, Input, OnInit, Output} from '@angular/core';
import {LocalstorageStateService} from '../services/localstorage-state.service';

@Directive({
    selector: '[appRowsLocalstoragePersistence]'
})
// This directive can be used on p-table components to save and retrieve the selected number of rows to localstorage
export class RowsLocalstoragePersistenceDirective implements OnInit {
    @Output() rowsChange: EventEmitter<number> = new EventEmitter();
    @Input() tableIdentifier: string;
    private keySuffix: string = '_rows';

    constructor(private localstorageStateService: LocalstorageStateService) {
    }

    ngOnInit(): void {
        if (!this.tableIdentifier) {
            throw new Error('@Input \'tableIdentifier\' needs to be set, so that pagination data can be stored in localStorage');
        }
        const [isSet, rowsFromLocalStorage] = this.localstorageStateService.get<number>(this.tableIdentifier + this.keySuffix);
        if (isSet) {
            this.rowsChange.emit(rowsFromLocalStorage);
        }

    }

    @HostListener('onPage', ['$event'])
    onPage(pageEvent: { first: number, rows: number }) {
        this.localstorageStateService.set<number>(this.tableIdentifier + this.keySuffix, pageEvent.rows)
    }
}

