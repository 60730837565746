import { Component, OnInit, SimpleChanges, OnChanges, EventEmitter, Input, Output } from '@angular/core';
import { SelectItem } from 'primeng/api';
import { Repraesentationart } from '../../models/repraesentationart.model';

@Component({
    selector: 'app-repraesentationart-dropdown',
    templateUrl: './repraesentationart-dropdown.component.html',
    styleUrls: ['./repraesentationart-dropdown.component.scss']
})
export class RepraesentationartDropdownComponent implements OnInit, OnChanges {
    @Input()
    public repraesentationarten: Repraesentationart[];

    @Input()
    public selectedRepraesentationartName: string;

    @Output()
    public repraesentationartChanged = new EventEmitter<Repraesentationart>();

    public repraesentationartenItems: SelectItem[];

    constructor() {
    }

    ngOnInit() {
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.repraesentationarten && changes.repraesentationarten.currentValue.length > 0) {
            this.repraesentationartenItems = this.repraesentationarten.map((art) => {
                return { label: art.name, value: art.name }
            });
        }
    }

    public onDropdownChange(event) {
        this.repraesentationartChanged.emit(this.repraesentationarten.find(art => art.name === event.value));
    }

}
