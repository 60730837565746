import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Subject} from "rxjs";
import {debounceTime} from "rxjs/operators";

@Component({
  selector: 'app-repraesentation-kommentar',
  templateUrl: './repraesentation-kommentar.component.html',
  styleUrls: ['./repraesentation-kommentar.component.scss']
})
export class RepraesentationKommentarComponent implements OnInit {

    @Output() public kommentarUpdated = new EventEmitter<string>();
    @Input() kommentar: string;
    currentKommentar$: Subject<string> = new Subject<string>();

    constructor() { }

      ngOnInit() {
          this.currentKommentar$
              .pipe(debounceTime(500))
              .subscribe(kommentar => {
                  this.kommentarUpdated.emit(kommentar);
              });

      }

      onChange(event) {
        this.currentKommentar$.next(event);
      }

}
