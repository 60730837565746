import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {Observable} from 'rxjs';
import {Kontakt} from '../models/kontakt.model';
import {HttpClient} from '@angular/common/http';
import * as fileSaver from 'file-saver';
import {KontakteImportResult} from '../models/kontakteImportResult.model.cs';
import {KeycloakService} from "keycloak-angular";


@Injectable()
export class KontakteDataService {
    baseUrl: string = environment.apiUrl;

    constructor(private http: HttpClient, private keycloak: KeycloakService) {
    }

    public getKontakte(): Observable<Kontakt[]> {
        const query = `${this.baseUrl}/kontakte`;
        return this.http.get<Kontakt[]>(query);
    }

    public refreshKontakt(abacusClientId: number) {
        const query = `${this.baseUrl}/kontakte/abacus/${abacusClientId}`;
        return this.http.patch<Kontakt>(query, {});
    }

    public getKontakt(id: number): Observable<Kontakt> {
        const query = `${this.baseUrl}/kontakte/${id}`;
        return this.http.get<Kontakt>(query);
    }

    public createKontaktFromAbacusId(abacusClientId: number): Observable<Kontakt> {
        const query = `${this.baseUrl}/kontakte/abacus`;
        return this.http.post<Kontakt>(query, abacusClientId);
    }

    public createKontakt(kontakt: Kontakt): Observable<Kontakt> {
        const query = `${this.baseUrl}/kontakte`;
        return this.http.post<Kontakt>(query, kontakt);
    }

    public updateKontakt(id: number, kontaktToUpdate: Kontakt) {
        const query = `${this.baseUrl}/kontakte/${id}`;
        return this.http.put(query, kontaktToUpdate);
    }

    public deleteKontakt(id: number) {
        const query = `${this.baseUrl}/kontakte/${id}`;
        return this.http.delete<Kontakt>(query);
    }

    public patchKontakt(id: number, patchOp: any): Observable<Kontakt> {
        const query = `${this.baseUrl}/kontakte/${id}`;
        return this.http.patch<Kontakt>(query, patchOp);
    }

    public async csvExport(ids: number[], filename: string = 'kontakte_export.csv') {
        let token = await this.keycloak.getToken();
        const query = `${this.baseUrl}/kontakte/csv`;
        const xhr = new XMLHttpRequest();
        xhr.open('POST', query, true);
        xhr.setRequestHeader('Authorization', `Bearer ${token}`);
        xhr.setRequestHeader('content-type', 'application/json');

        xhr.responseType = 'blob';
        xhr.onreadystatechange = function () {
            if (xhr.readyState === 4 && xhr.status === 200) {
                const blob = new Blob([this.response], {type: 'application/csv'});
                fileSaver.saveAs(blob, filename);
            }
        };
        xhr.send(JSON.stringify(ids));
    }

    public csvImport(file: File): Observable<KontakteImportResult> {
        const query = `${this.baseUrl}/kontakte/csv-import`;
        const formData: FormData = new FormData();
        formData.append('file', file, file.name);
        return this.http.post<KontakteImportResult>(query, formData);
    }

    async downloadExample() {
        const token = await this.keycloak.getToken();
        const query = `${this.baseUrl}/kontakte/csv-import/example`;
        const xhr = new XMLHttpRequest();
        xhr.open('GET', query, true);
        xhr.setRequestHeader('Authorization', `Bearer ${token}`);
        xhr.setRequestHeader('content-type', 'application/json');

        xhr.responseType = 'blob';
        xhr.onreadystatechange = function () {
            if (xhr.readyState === 4 && xhr.status === 200) {
                const blob = new Blob([this.response], {type: 'application/csv'});
                fileSaver.saveAs(blob, 'kontakte_import.csv');
            }
        };
        xhr.send();
    }
}
