import {Injectable} from '@angular/core';
import {ToastrService} from 'ngx-toastr';
import {IndividualConfig} from 'ngx-toastr/toastr/toastr-config';

@Injectable()
export class MercatusToastyService {
    private globalToastConfig = {
        positionClass: 'toast-bottom-right'
    };

    constructor(private toastrService: ToastrService) {
    }


    public showSuccessToast(message: string, config?: Partial<IndividualConfig>) {
        this.toastrService.success(message, 'Erfolg', Object.assign(this.globalToastConfig, config));
    };

    public showWarningToast(message: string, config?: Partial<IndividualConfig>) {
        this.toastrService.warning(message, 'Warnung', Object.assign(this.globalToastConfig, config));
    };


    public showErrorToast(message: string, config?: Partial<IndividualConfig>) {
        this.toastrService.error(message, 'Fehler', Object.assign(this.globalToastConfig, config));
    };
}
