<div *ngIf="repraesentation.teilnahmen.length > 0" class="col-xs-12 margin-t-20">
    <table class="table table-condensed borderless margin-b-0">
        <tr>
            <th colspan="1">Teilnahmen</th>
            <th colspan="1">Standplatz</th>
        </tr>
        <tr *ngFor="let teilnahme of repraesentation.teilnahmen | slice:0:maxNoOfFruehereTeilnahmenToShow">
            <td>
                <a routerLink="/veranstaltungen/{{teilnahme.veranstaltung.id}}/anmeldungen">{{teilnahme.veranstaltung?.name}}</a>
            </td>
            <td>
                <a *ngIf="teilnahme.standplatz" style="cursor: pointer"
                   (click)="navigateToStandplatz(teilnahme)">{{teilnahme.standplatz.fullDescription}}</a>
                <span *ngIf="!teilnahme.standplatz" style="padding-right: 1.5em;">&ndash;</span>
            </td>
        </tr>
        <tr *ngIf="repraesentation.teilnahmen.length > maxNoOfFruehereTeilnahmenToShow">
            <td colspan="2"><a
                (click)="maxNoOfFruehereTeilnahmenToShow = repraesentation.teilnahmen.length"
                style="cursor: pointer">Mehr anzeigen...</a></td>
        </tr>
        <tr *ngIf="repraesentation.teilnahmen.length === 0">
            <td class="text-muted">Keine Teilnahmen</td>
        </tr>
    </table>
</div>