import {Component, Input, OnChanges, SimpleChanges} from "@angular/core";

@Component({
    selector: 'app-diff-viewer',
    templateUrl: './diff-viewer.component.html'
})
export class DiffViewerComponent implements OnChanges {

    @Input()
    public diffGroupDefinitions: DiffGroupDefinition[];

    public diffGroups: DiffGroup[] = [];

    public showOld: boolean = true;
    public showNew: boolean = true;

    private generateDiffs(properties: DiffProperty[], oldObject: any, newObject: any) {
        const result: Diff[] = [];
        for (let property of properties) {
            let oldValue = oldObject ? property.value(oldObject) ?? '': '';
            let newValue = newObject ? property.value(newObject) ?? '': '';
            if (oldValue == newValue) continue;
            result.push({displayName: property.displayName, oldValue, newValue});
        }

        return result;
    }

    private generateDiffGroups(def: DiffGroupDefinition): DiffGroup[] {
        const diffGroups: DiffGroup[] = [];
        def.oldItems.forEach((oldRep, index) => {
            const newRep = def.newItems[index];
            const repDiffs = this.generateDiffs(def.properties, oldRep, newRep);
            if (repDiffs.length > 0) diffGroups.push({
                name: def.groupNameAddition
                    ? `${def.groupName} - ${def.groupNameAddition(oldRep)}`
                    : def.groupName,
                diffs: repDiffs
            });
        });

        return diffGroups;
    }

    ngOnChanges(_: SimpleChanges): void {
        if (!this.diffGroupDefinitions) {
            return;
        }

        this.diffGroups = [];
        this.diffGroupDefinitions.forEach(groupDef => this.diffGroups = this.diffGroups.concat(this.generateDiffGroups(groupDef)));
        this.showOld = this.diffGroups.find(dg => dg.diffs.find(d => d.oldValue != '') != null) != null;
        this.showNew = this.diffGroups.find(dg => dg.diffs.find(d => d.newValue!= '') != null) != null;
    }
}

export interface Diff {
    displayName: string;
    oldValue: string;
    newValue: string;
}

export interface DiffGroup {
    name: string;
    diffs: Diff[];
}

export interface DiffProperty {
    displayName: string;
    value: (obj: any) => string;
}

export interface DiffGroupDefinition {
    groupName: string;
    properties: DiffProperty[];
    oldItems: any[];
    newItems: any[];
    groupNameAddition?: (obj: any) => string;
}
