<div class="row padding-b-20" *ngIf="bedarfeToSelect.length > 0">
    <div class="col-xs-12" style="margin-bottom: 10px">
        <p-dropdown [options]="bedarfeToSelect" name="bedarf" optionLabel="beschreibung" [style]="{'width':'100%'}"
                    [(ngModel)]="formModel.bedarf"
                    placeholder="Bitte wählen..."
                    id="bedarfeDropDown">
        </p-dropdown>
    </div>
    <div class="col-xs-6">
        <input type="number" class="form-control" class="form-control" name="menge" placeholder="Menge"
                [(ngModel)]="formModel.menge"/>
    </div>
    <div class="col-xs-6 text-right">
        <button [disabled]="!formModel.bedarf || formModel.menge < 0.001" class="btn btn-primary" type="button" (click)="addBedarf()">Hinzufügen</button>
    </div>
</div>

<p-table *ngIf="assignedBedarfe.length > 0" #bedarfeTable [value]="assignedBedarfe" [autoLayout]="true">
    <ng-template pTemplate="header">
        <tr>
            <th>Beschreibung</th>
            <th>Menge</th>
            <th></th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-assignedBedarf>
        <tr>
            <td>{{assignedBedarf.bedarf.beschreibung}}</td>
            <td>{{assignedBedarf.menge}}</td>
            <td>
                <i class="fa fa-trash-o" style="cursor: pointer;" (click)="removeRelation(assignedBedarf)"></i>
            </td>
        </tr>
    </ng-template>
</p-table>