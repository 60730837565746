import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class TenantSettingsDataService {
    baseUrl: string = `${environment.apiUrl}/settings`;

    constructor(private http: HttpClient) {
    }

    getSettings(): Observable<any> {
        return this.http.get<any>(this.baseUrl);
    }

    updateSettings(settings: any): Observable<any> {
        return this.http.post<any>(this.baseUrl, settings);
    }

    initializeSettings() {
        const query = `${this.baseUrl}/initialize`;
        return this.http.post<any>(query, {});
    }
}
