import {Component, Input, OnInit} from '@angular/core';
import {Teilnahme} from '../../models/teilnahme.model';

@Component({
    selector: 'app-laufmeter-information',
    templateUrl: './laufmeter-information.component.html',
    styleUrls: ['./laufmeter-information.component.scss']
})
export class LaufmeterInformationComponent implements OnInit {

    @Input() teilnahme: Teilnahme;
    maxTiefe: number = 0;

    constructor() {
    }

    ngOnInit() {
        this.maxTiefe = Math.max(this.teilnahme.tiefe, this.teilnahme.tiefeGemeindestand, this.teilnahme.tiefeAndere);
    }

    getLaufmeterInformationTooltip(teilnahme: Teilnahme) {
        const normalTiefe = teilnahme.tiefe > 0 ? 'x' + teilnahme.tiefe + 'm' : 'm'
        const normal = `Normal:${teilnahme.laufmeter}` + normalTiefe;

        const gemeindestandTiefe = teilnahme.tiefeGemeindestand > 0 ? 'x' + teilnahme.tiefeGemeindestand + 'm' : 'm'
        const gemeindestand = ` Gemeindestand:${teilnahme.laufmeterGemeindestand}` + gemeindestandTiefe;

        const andereTiefe = teilnahme.tiefeAndere > 0 ? 'x' + teilnahme.tiefeAndere + 'm' : 'm'
        const andere = ` Andere:${teilnahme.laufmeterAndere}` + andereTiefe;

        let tooltip = normal;
        if (teilnahme.laufmeterGemeindestand > 0 || teilnahme.tiefeGemeindestand > 0) {
            tooltip += gemeindestand;
        }
        if (teilnahme.laufmeterAndere > 0 || teilnahme.tiefeAndere > 0) {
            tooltip += andere;
        }
        return tooltip;
    }

}
