import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {Observable} from 'rxjs';
import {Repraesentation} from '../models/repraesentation.model';
import {HttpClient} from '@angular/common/http';
import {RepraesentationBedarf} from "../models/repraesentationBedarf.model";

@Injectable({
    providedIn: 'root'
})
export class RepraesentationenDataService {
    baseUrl: string = environment.apiUrl;

    constructor(private http: HttpClient) {
    }

    public getRepraesentationen(): Observable<Repraesentation[]> {
        const query = `${this.baseUrl}/repraesentationen`;
        return this.http.get<Repraesentation[]>(query);
    }

    public getAbacusRepresentationen(search: string): Observable<Repraesentation[]> {
        const query = `${this.baseUrl}/repraesentationen/abacus/${search}`;
        return this.http.get<Repraesentation[]>(query);
    }

    createNewRepraesentation(repraesentationToEditOrCreate: Repraesentation): Observable<Repraesentation> {
        const query = `${this.baseUrl}/repraesentationen`;
        return this.http.post<Repraesentation>(query, repraesentationToEditOrCreate);
    }

    updateRepraesentation(repraesentationToEditOrCreate: Repraesentation): Observable<Repraesentation> {
        const updateDto= {
            id: repraesentationToEditOrCreate.id,
            name: repraesentationToEditOrCreate.name,
            angebotsart: repraesentationToEditOrCreate.angebotsart,
            repraesentationart: repraesentationToEditOrCreate.repraesentationart,
            kommentar: repraesentationToEditOrCreate.kommentar,
            customData: repraesentationToEditOrCreate.customData,
            laufmeterGemeindestand: repraesentationToEditOrCreate.laufmeterGemeindestand,
            tiefeGemeindestand: repraesentationToEditOrCreate.tiefeGemeindestand,
            laufmeterAndere: repraesentationToEditOrCreate.laufmeterAndere,
            tiefeAndere: repraesentationToEditOrCreate.tiefeAndere,
            laufmeter: repraesentationToEditOrCreate.laufmeter,
            tiefe: repraesentationToEditOrCreate.tiefe,
            durchmesser: repraesentationToEditOrCreate.durchmesser,
            hoehe: repraesentationToEditOrCreate.hoehe
        };
        const query = `${this.baseUrl}/repraesentationen`;
        return this.http.put<Repraesentation>(query, updateDto);
    }

    removeRepraesentation(id: number): Observable<Repraesentation> {
        const query = `${this.baseUrl}/repraesentationen/${id}`;
        return this.http.delete<Repraesentation>(query);
    }

    public addBedarfToRepraesentation(id: number, relation: RepraesentationBedarf): Observable<RepraesentationBedarf> {
        const query = `${this.baseUrl}/repraesentationen/${id}/bedarfe`;
        return this.http.post<RepraesentationBedarf>(query, relation);
    }

    public removeBedarfFromRepraesentation(id: number, bedarfId: number): Observable<any> {
        const query = `${this.baseUrl}/repraesentationen/${id}/bedarfe/${bedarfId}`;
        return this.http.delete<RepraesentationBedarf>(query);
    }
}
