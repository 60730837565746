import {Injectable} from '@angular/core';
import {StateStorage} from '../models/state-storage.interface';

@Injectable({
    providedIn: 'root'
})
export class LocalstorageStateService implements StateStorage {

    constructor() {
    }

    set<T>(key: string, value: T) {
        localStorage.setItem(key, JSON.stringify(value));
    }

    overwriteIfExists<T>(key: string, initialValue: T): T {
        const [exists, value] = this.get<T>(key);
        return exists ? value : initialValue;
    }

    get<T>(key: string): [boolean, T] {
        const itemJsonString = localStorage.getItem(key);
        const exists = itemJsonString !== null;

        try {
            const value = JSON.parse(itemJsonString) as T;
            return [exists, value];
        } catch (e) {
            return [exists, null];
        }
    }
}
