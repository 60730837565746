import { BaseEntity } from './baseEntity.model';
import { Repraesentation } from './repraesentation.model';

export class Produkt extends BaseEntity {
    name: string;
    repraesentationen?: Repraesentation[];
    editMode: boolean;
    numberOfRepraesentation: number;

    constructor(name: string) {
        super();
        this.name = name;
        this.editMode = false;
    }
}
