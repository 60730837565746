import {Injectable} from '@angular/core';
import {Produkt} from '../../../shared/models/produkt.model';
import {environment} from '../../../../environments/environment';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import { Repraesentation } from '../../../shared/models/repraesentation.model';
import * as fileSaver from 'file-saver';
import {KeycloakService} from "keycloak-angular";

@Injectable({
    providedIn: 'root'
})
export class ProdukteDataService {
    baseUrl: string = `${environment.apiUrl}/produkte`;

    constructor(private http: HttpClient, private keycloak: KeycloakService) {
    }

    public getProdukte(): Observable<Produkt[]> {
        return this.http.get<Produkt[]>(this.baseUrl);
    }

    public getKontakteByRepraesentation(produktId: number): Observable<Repraesentation[]> {
        const query = `${this.baseUrl}/produkt/${produktId}/repraesentationen`;
        return this.http.get<Repraesentation[]>(query);
    }

    public createNewProduct(product: Produkt): Observable<Produkt> {
        const query = `${this.baseUrl}`;
        return this.http.post<Produkt>(query, product);
    }

    public deleteProduct(id: number): Observable<Produkt> {
        const query = `${this.baseUrl}/${id}`;
        return this.http.delete<Produkt>(query);
    }

    public updateProduct(product: Produkt): Observable<Produkt> {
        const query = `${this.baseUrl}/${product.id}`;
        return this.http.put<Produkt>(query, product);
    }

    public async csvExport(produkteIds: number[], filename: string = 'produktesortiment_export.csv') {
        const token = await this.keycloak.getToken();
        const query = `${this.baseUrl}/csv`;
        const xhr = new XMLHttpRequest();
        xhr.open('POST', query, true);
        xhr.setRequestHeader('Authorization', `Bearer ${token}`);
        xhr.setRequestHeader('content-type', 'application/json');

        xhr.responseType = 'blob';

        xhr.onreadystatechange = function () {
            if (xhr.readyState === 4 && xhr.status === 200) {
                const blob = new Blob([this.response], {type: 'application/csv'});
                fileSaver.saveAs(blob, filename);
            }
        };
        xhr.send(JSON.stringify(produkteIds));
    }
}
