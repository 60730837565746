<div class="container">

    <div *ngIf="!showForm" class="row">
        <div class="col-md-12 w-100">
            <div class="pull-right">
                <button class="btn btn-primary" (click)="add()"><i class="fa fa-plus"></i> Neuer Upload</button>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-md-12">
            <app-upload-form
                *ngIf="showForm"
                [form]="form"
                [mode]="formMode"
                (saved)="savedForm($event)"
                (canceled)="showForm = false">
            </app-upload-form>
        </div>
    </div>

    <p-table *ngIf="!showForm" class="report-tables" [value]="uploads">
        <ng-template pTemplate="header">
            <tr>
                <th>Bezeichnung</th>
                <th>Dateiname</th>
                <th></th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-row>
            <tr>
                <td>{{row.description}}</td>
                <td>{{row.fileName}}</td>
                <td>
                    <button (click)="edit(row)" class="btn btn-xs btn-default">
                        <i class="fa fa-pencil"></i>
                    </button>
                    &nbsp;
                    <button (click)="showDeleteDialog(row)" class="btn btn-xs btn-default">
                        <i class="fa fa-trash"></i>
                    </button>
                    &nbsp;
                    <button class="btn btn-xs btn-default" (click)="download(row)" title="Upload herunterladen">
                        <i class="fa fa-download"></i>
                    </button>
                </td>
            </tr>
        </ng-template>
    </p-table>
</div>

<p-confirmDialog
    [key]="dialogKey"
    icon="fa fa-question-circle"
    [style]="{width: '425px'}"
    #cd>
    <p-footer>
        <button class="btn btn-default" (click)="cd.reject()">Behalten</button>
        <button class="btn btn-danger" (click)="cd.accept()"><i class="fa fa-trash-o"></i> Löschen</button>
    </p-footer>
</p-confirmDialog>
