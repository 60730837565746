import {Component, Inject, OnInit} from '@angular/core';
import {Router, NavigationEnd} from '@angular/router';
import {bufferTime, filter, map} from 'rxjs/operators';
import {BehaviorSubject} from 'rxjs';
import { SettingsStore } from '../../../settings/store/settings-store.service';
import {KeycloakService} from "keycloak-angular";
import {KeycloakProfile} from "keycloak-js";
import {DOCUMENT} from "@angular/common";

@Component({
    selector: 'app-top-nav',
    templateUrl: './top-nav.component.html',
    styleUrls: ['./top-nav.component.scss']
})
export class HeaderComponent implements OnInit {
    userNameClick$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
    navItems = [
        {
            title: 'Dashboard',
            icon: '/assets/img/topnav/mercatus-dashboard.svg',
            link: '/dashboard',
            baseLink: '/dashboard',
            active: true
        },
        {
            title: 'Veranstaltungen',
            icon: '/assets/img/topnav/mercatus-events.svg',
            link: '/veranstaltungen/all',
            baseLink: '/veranstaltungen',
            active: false,
            items: [
                {
                    title: 'Alle Veranstaltungen',
                    link: '/veranstaltungen/all',
                    active: false
                },
                {
                    title: 'Neu erstellen',
                    link: '/veranstaltungen/mutate/new',
                    iconClass: 'fa fa-plus',
                    active: false
                },
            ]

        },
        {
            title: 'Kontakte',
            icon: '/assets/img/topnav/mercatus-contacts.svg',
            link: '/kontakte',
            baseLink: '/kontakte',
            active: false,
            items: [
                {
                    title: 'Alle Kontakte',
                    link: '/kontakte',
                    active: false,
                },
                {
                    title: 'Neu erstellen',
                    link: '/kontakte/new',
                    iconClass: 'fa fa-plus',
                    active: false,
                },
            ]
        },
        {
            title: 'Einstellungen',
            icon: '/assets/img/topnav/mercatus-settings.svg',
            link: '/settings/bedarfe',
            baseLink: '/settings',
            active: false
        },
    ];

    public userProfile: KeycloakProfile | null = null;

    constructor(@Inject(DOCUMENT) private document: any, private readonly keycloak: KeycloakService, private router: Router, private settingsStore: SettingsStore) {
        if (this.keycloak.isLoggedIn()) {
            this.settingsStore.get().subscribe((settingsState) => {
                if (settingsState.settings.abacusSettings?.contactsManagedByAbacus == true) {
                    this.navItems.find(i => i.title === "Kontakte").items.pop();
                }
            });
        }
    }


    async ngOnInit() {
        this.userNameClick$
            .pipe(
                bufferTime(2000, null, 3),
                map(list => list.length),
                filter(x => x >= 3)
            )
            .subscribe(() => {
                this.router.navigate(['/admin/userinfo']);
            });

        this.router.events.pipe(filter(e => e instanceof NavigationEnd)).subscribe((navEnd: NavigationEnd) => {
            this.navItems.forEach(item => {
                item.items?.forEach(subItem => {
                    subItem.active = subItem.link === navEnd.url;
                });
                item.active = navEnd.url.includes(item.baseLink);
            });

            this.navItems = [...this.navItems];
        });

        this.userProfile = this.keycloak.isLoggedIn()
        ? await this.keycloak.loadUserProfile()
        : null;
    }

    async logout() {
        await this.keycloak.logout(this.document.location.origin);
    }
}
