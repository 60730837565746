import {Component, EventEmitter, Input, Output} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';

@Component({
    selector: 'app-upload-form',
    templateUrl: './upload-form.component.html',
    styleUrls: ['./upload-form.component.scss']
})
export class UploadFormComponent {

    allowedContentTypes = [
        "application/vnd.ms-excel",
        "application/vnd.ms-powerpoint",
        "application/msword",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        "application/vnd.openxmlformats-officedocument.presentationml.presentation",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        "application/vnd.oasis.opendocument.spreadsheet",
        "application/vnd.oasis.opendocument.presentation",
        "application/vnd.oasis.opendocument.text",
        "application/vnd.visio",
        "application/json",
        "application/pdf",
        "application/rtf",
        "application/xml",
        "image/*",
        "text/csv",
        "text/xml",
        ].join(",");

    @Input() form: UntypedFormGroup;
    @Input() mode: 'add' | 'edit' = 'edit';
    @Output() saved: EventEmitter<{ form: UntypedFormGroup, file?: File }> = new EventEmitter<{ form: UntypedFormGroup, file?: File }>();
    @Output() canceled: EventEmitter<any> = new EventEmitter<any>();
    file: File;

    save() {
        this.saved.emit({form: this.form, file: this.file});
    }
}
