<div class="icon-selector-container" *ngIf="showIconSelector">
    <div style="text-align: right; margin: 8px 8px 0 0; cursor: pointer" (click)="showIconSelector = false"><i class="fa fa-times" style="color: gray"></i></div>
    <div class="row">
        <div class="col-xs-3" *ngFor="let fileName of iconFileNames">
            <div class="icon">
                <img [src]="'/assets/img/markets/'+fileName" width="130px" style="cursor: pointer; margin-bottom: 24px;" (click)="setSerieIcon(fileName)">
            </div>
        </div>
    </div>
</div>
