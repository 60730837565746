import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {Bedarf} from "../../models/bedarf.model";
import {IBedarfRelation} from "../../models/bedarfRelation.interface";

@Component({
    selector: 'app-bedarfe-edit',
    templateUrl: './bedarfe-edit.component.html',
    styleUrls: ['./bedarfe-edit.component.scss']
})
export class BedarfeEditComponent implements OnInit, OnChanges {

    @Input() public bedarfe: Bedarf[];
    @Input() public assignedBedarfe: IBedarfRelation[];

    @Output() public bedarfAdded = new EventEmitter<IBedarfRelation>();
    @Output() public bedarfRemoved = new EventEmitter<Bedarf>();

    public bedarfeToSelect: Bedarf[];
    public formModel = new BedarfFormModel();

    constructor() {
    }

    ngOnInit() {
    }

    ngOnChanges(changes: SimpleChanges) {
        this.resetData();
    }

    private resetData() {
        this.bedarfeToSelect = this.bedarfe.filter((bed) =>
            this.assignedBedarfe.every(ab => ab.bedarf.id !== bed.id)
        );
        this.formModel = {bedarf: null, menge: null};
    }

    public addBedarf() {
        this.bedarfAdded.emit(this.formModel);
    }

    public removeRelation(relation: IBedarfRelation) {
        this.bedarfRemoved.emit(relation.bedarf);
    }

}

class BedarfFormModel {
    public bedarf: Bedarf;
    public menge: number;
}
