import {Injectable} from "@angular/core";
import {environment} from "../../../environments/environment";
import {Observable} from 'rxjs';
import {HttpClient} from "@angular/common/http";
import {Angebotsart} from "../models/angebotsart.model";

@Injectable()
export class AngebotsartenDataService {
    baseUrl: string = environment.apiUrl;

    constructor(private http: HttpClient) {
    }

    public getAngebotsarten(): Observable<Angebotsart[]> {
        const query = `${this.baseUrl}/angebotsarten`;
        return this.http.get<Angebotsart[]>(query);
    }
}
