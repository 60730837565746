import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-veranstaltung-date',
    templateUrl: './veranstaltung-date.component.html',
    styleUrls: ['./veranstaltung-date.component.scss']
})
export class VeranstaltungDateComponent implements OnInit {
    @Input() datumVon: Date;
    @Input() datumBis: Date;
    @Input() showYear: boolean = true;
    @Input() shortMonth: boolean = true;

    constructor() {
    }

    ngOnInit() {
    }

    getDateFormatterString(): string {
        const monthString = this.shortMonth ? 'MMM' : 'MMMM';
        return this.showYear ? `d. ${monthString} yyyy` : `d. ${monthString}`;
    }


    getFromDateFormatterString(): string {
        // check if month the same
        if (new Date(this.datumVon).getMonth() === new Date(this.datumBis).getMonth()) {
            return `d.`;
        } else {
            const monthString = this.shortMonth ? 'MMM' : 'MMMM';
            // check if year the same
            if (new Date(this.datumVon).getFullYear() === new Date(this.datumBis).getFullYear()) {
                return `d. ${monthString}`;
            } else {
                return this.showYear ? `d. ${monthString} yyyy` : `d. ${monthString}`;
            }
        }
    }

    oneDayVeranstaltung(): boolean {
        if (this.datumVon && this.datumBis) {
            return this.datumVon.valueOf() === this.datumBis.valueOf();
        }
        return false;
    }

}
