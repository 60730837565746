<span class="p-fluid">
    <p-dropdown
        [options]="angebotsartenItems"
        [(ngModel)]="selectedAngebotsartName"
        (onChange)="onDropdownChange($event)"
        id="angebotsartenItemsDropdown">
        <ng-template let-item pTemplate="selectedItem">
            <app-angebotsart [name]="item.label" [icon]="item.icon"></app-angebotsart> {{item.label}}
        </ng-template>
        <ng-template let-item pTemplate="item">
            <app-angebotsart [name]="item.label" [icon]="item.icon"></app-angebotsart> {{item.label}}
        </ng-template>

    </p-dropdown>

</span>
