<div class="stock-counter" (keyup)="validateAndUpdateForm($event)">
    <div>
        <div>
            <p><input id="quantitySelectorId" type="number" [(ngModel)]="value" [min]="min" [max]="max"  [readonly]="isReadOnly ? true : null"></p>
            <div>
                <button type="button" (click)="increment()" [disabled]="value === max || maxDisabled" class="increment">+</button>
                <button type="button" (click)="decrement()" [disabled]="value === min || minDisabled" class="decrement">&ndash;</button>
            </div>
        </div>
    </div>
</div>
