<span class="p-fluid">
    <p-dropdown
        [options]="repraesentationartenItems"
        placeholder="Bitte wählen..."
        [(ngModel)]="selectedRepraesentationartName"
        (onChange)="onDropdownChange($event)"
        id="repraesentationartDropdown">
        <ng-template let-item pTemplate="selectedItem">
            {{item.label}}
        </ng-template>
        <ng-template let-item pTemplate="item">
            {{item.label}}
        </ng-template>
    </p-dropdown>
</span>
