<div class="container-fluid">
    <div class="row">
        <ng-container *ngIf="reportRecordTopGroups?.length < 1">
            <div class="col-xs-12">
                <p class="margin-t-20">
                    <strong>Es wurden noch keine Reports generiert.</strong>
                </p>
            </div>
        </ng-container>
        <ng-container *ngIf="reportRecordTopGroups?.length > 0">
            <div class="col-xs-6"> 
                <ng-container *ngFor="let topGroup of reportRecordTopGroups">
                    <h4>{{topGroup.name}}</h4>
                    <p-table [value]="topGroup.records" [selection]="selectedRecord">
                        <ng-template pTemplate="header">
                            <tr>
                                <th>Report Name</th>
                                <th>Generiert am</th>
                                <th style="width: 40px;"></th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-rowData let-rowIndex="rowIndex" let-expanded="expanded" let-columns="columns">
                            <tr>
                                <td><i class="fa fa-file-pdf-o"></i>&nbsp;<a style="cursor: pointer;" (click)="getPdf(rowData.record)">{{rowData.name}}</a></td>
                                <td>{{rowData.record.createdOn | date: 'dd.MM.yyyy (HH:mm)'}}</td>
                                <td><a style="cursor: pointer;" title="Frühere Versionen anzeigen..." (click)="this.getRecordHistory(rowData.record.fileName)"><i class="fa fa-clock-o"></i></a></td>
                            </tr>
                            <ng-container *ngIf="rowData.record.fileName == this.currentFilename && this.showHistory">
                            <tr *ngFor="let selectedReportRecord of reportRecordHistory" class="container-light-gray">
                                <td><i class="fa fa-file-pdf-o"></i>&nbsp;<a style="cursor: pointer;" (click)="getPdf(selectedReportRecord)">{{rowData.name}} (frühere Version)</a></td>
                                <td>{{selectedReportRecord.createdOn | date: 'dd.MM.yyyy (HH:mm)'}}</td>
                            </tr>
                        </ng-container>
                        </ng-template>
                    </p-table>
                </ng-container>
            </div>
            <div class="col-xs-6">
                <h4>{{pdfPreviewName}}</h4>
                <app-pdf-preview [data]="pdf"></app-pdf-preview>
            </div>
        </ng-container>
    </div>
    
</div>