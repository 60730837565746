import { BaseEntity } from './baseEntity.model';
import { Teilnahme } from './teilnahme.model';
import { Veranstaltungsserie } from './veranstaltungsSerie.model';

export class Veranstaltung extends BaseEntity {
    teilnahmen: Teilnahme[];
    name: string;
    datumVon: string;
    datumBis: string;
    veranstaltungsserie: Veranstaltungsserie;
    ort: string;
    isFavorite: boolean;
    kostenstelleId: number;
    hasManualStandplatzNumbering: boolean;
    isOpenForApplication: boolean;
    myMercatusVisibility: MyMercatusVisibility;
}

export enum MyMercatusVisibility {
    Hidden = 0,
    Contacts = 1,
    MyMercatusUsers = 2,
}

