import { Produkt } from './produkt.model';
import { Repraesentation } from './repraesentation.model';

export class Sortiment {
    repraesentationId: number;
    repraesentation: Repraesentation;

    produktId: number;
    produkt: Produkt;

    constructor(repraesentationId: number, produktId: number) {
        this.repraesentationId = repraesentationId;
        this.produktId = produktId;
    }
}
