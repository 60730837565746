import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { ReportRecord, ReportRecordTopGroup } from "../../models/reportRecord";
import { ReportingDataService } from "../../services/reporting-data.service";

@Component({
    selector: 'app-reporting-history',
    templateUrl: './reporting-history.component.html',
    styleUrls: ['./reporting-history.component.scss']
})
export class ReportingHistoryComponent implements OnInit, OnChanges {
    @Input() reportRecordTopGroups: ReportRecordTopGroup[];
    pdf: SafeResourceUrl;
    pdfPreviewName: string = " ";
    selectedRecord: ReportRecord;
    reportRecordHistory: ReportRecord[];
    currentFilename: string;
    showHistory: boolean;


    constructor(
        private reportingDataService: ReportingDataService,
        private sanitizer: DomSanitizer) {
    }

    ngOnInit() {
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (this.hasFirstRecord(this.reportRecordTopGroups)) {
            this.getPdf(this.reportRecordTopGroups[0].records[0].record)
        }
    }

    getPdf(reportRecord: ReportRecord) {
        this.reportingDataService.getReportHistoryPdf(reportRecord.fileName)
            .subscribe((res) => {
                this.setPdfUrl(res);
                let previewName = reportRecord.reportName;
                if (reportRecord.kontaktName != null) {
                    previewName += ` (${reportRecord.kontaktName})`
                }
                this.pdfPreviewName = previewName;
                this.selectedRecord = reportRecord;
            }, (error) => {
                this.setPdfUrl("");
                this.pdfPreviewName = "";
                this.selectedRecord = null;
            });
    }

      getRecordHistory(filename: string) {
          this.showHistory = false;
        if (this.currentFilename === filename) {
            this.currentFilename = '';
        } else {
            this.currentFilename = filename;
            this.reportingDataService.getOldReportHistoryPdf(filename).subscribe(res => {
                this.reportRecordHistory = res;
                this.showHistory = true;
            })
        }
    }

    private setPdfUrl(url) {
        if (url) {
            this.pdf = this.sanitizer.bypassSecurityTrustResourceUrl(URL.createObjectURL(url));
        } else {
            this.pdf = this.sanitizer.bypassSecurityTrustResourceUrl("");
        }
    }

    private hasFirstRecord(tg: ReportRecordTopGroup[]): boolean {
        return tg && tg.length > 0 && tg[0].records && tg[0].records.length > 0
    }


}
