import { Component, forwardRef, Input, OnInit, EventEmitter, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
    selector: 'app-quantity-selector',
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => QuantitySelectorComponent),
        multi: true
    }],
    templateUrl: './quantity-selector.component.html',
    styleUrls: ['./quantity-selector.component.scss']
})
export class QuantitySelectorComponent implements OnInit, ControlValueAccessor {
    @Input() step: number = 1;
    @Input() min: number = 0;
    @Input() max: number = 999999;
    @Input() maxDisabled: boolean = false;
    @Input() minDisabled: boolean = false;
    @Input() isReadOnly: boolean = false;
    @Output() stepUp = new EventEmitter();
    @Output() stepDown = new EventEmitter();

    value: number = 0;
    private onTouch: Function;
    private onModelChange: Function;

    constructor() {
    }

    ngOnInit() {
    }

    registerOnChange(fn: any): void {
        this.onModelChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouch = fn;
    }

    writeValue(value: any): void {
        this.value = value || 0;
    }

    increment() {
        if (this.value < this.max) {
            this.value += this.step;
            this.onModelChange(this.value);
        this.stepUp.emit();
        }
        this.validateAndUpdateForm();
    }

    decrement() {
        if (this.value > this.min) {
            this.value -= this.step;
            this.onModelChange(this.value);
            this.stepDown.emit();
        }
            this.validateAndUpdateForm();
    }

    validateAndUpdateForm(event?: any) {
        if(event && event.key == 'ArrowUp'){
            event.preventDefault()
            this.increment()
        } else if (event && event.key == 'ArrowDown') {
            event.preventDefault()
            this.decrement();
        }
        if (this.value > this.max) {
            this.value = this.max;
        }
        if (this.value < this.min) {
            this.value = this.min;
        }
        this.onModelChange(this.value);
        this.onTouch();
    }
}
