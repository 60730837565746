import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'app-angebotsart',
    templateUrl: './angebotsart.component.html',
    styleUrls: ['./angebotsart.component.scss']
})
export class AngebotsartComponent implements OnInit {
    @Input() name: string;
    @Input() sortiment: string;
    @Input() icon: string;

    constructor() {
    }

    ngOnInit() {
    }

}
