import { BaseEntity } from './baseEntity.model';
import { Standplatz } from './standplatz.model';
import { Veranstaltung } from './veranstaltung.model';
import { TeilnahmeStatusJournalEntry } from './teilnahmeStatusJournalEntry.model';
import { Repraesentation } from './repraesentation.model';
import {TeilnahmeBedarf} from "./teilnahmeBedarf.model";
import {Angebotsart} from "./angebotsart.model";
import {AppliedLeistung} from './appliedLeistung.model';
import {Produkt} from './produkt.model';
import {SelectItem} from 'primeng/api';

export class Teilnahme extends BaseEntity {
    teilnahmeNr: string;
    repraesentation: Repraesentation;
    standplatz: Standplatz;
    veranstaltung: Veranstaltung;
    status: TeilnahmeStatusJournalEntry;
    statusJournal: TeilnahmeStatusJournalEntry[];
    kommentar: string;
    kommentarExtern: string;
    laufmeter: number;
    laufmeterGemeindestand: number;
    tiefeGemeindestand: number;
    laufmeterAndere: number;
    tiefeAndere: number;
    laufmeterSum: number;
    tiefe: number;
    flaeche: number;
    durchmesser: number;
    hoehe: number;
    appliedLeistungen: AppliedLeistung[];
    bedarfe: TeilnahmeBedarf[];
    angebotsart: Angebotsart;
    angebotsartName: string;
    sortimentString: string;
    teilnahmeProdukte: Produkt[];
}

// Status with the property 'onlyForFiltering' set to true can only only be selected
// in UI used for filtering. They can note be applied by the user
const TeilnahemStatus: (SelectItem & { onlyForFiltering?: boolean })[] = [
    {label: 'Provisorisch', value: 2},
    {label: 'Generiert', value: 1, onlyForFiltering: true},
    {label: 'Anmeldung zugestellt', value: 10},
    {label: 'Absage Teilnehmer', value: 9},
    {label: 'Anmeldung eingegangen', value: 11},
    {label: 'Warteliste', value: 6},
    {label: 'Absage', value: 4},
    {label: 'Zusage', value: 3},
    {label: 'Vertrag zugestellt', value: 8},
    {label: 'Vertrag eingegangen', value: 12},
    {label: 'Abgerechnet', value: 5},
    {label: 'Entfernt', value: 7, onlyForFiltering: true}
]

// Used for when a user wants to set the status of a teilnahme
export function getTeilnahmeSelectionModel(): SelectItem[] {
    return TeilnahemStatus.filter(s => !s.onlyForFiltering);
}

// Used for when a user wants to filter on the status property, where all status need to be available.
export function getTeilnahmeFilterModel(): SelectItem[] {
    return TeilnahemStatus;
}

export function getTeilnahmeStatusModel() {
    const statusModel = {};
    for (const obj of getTeilnahmeFilterModel()) {
        statusModel[obj.value] = obj.label;
    }
    return statusModel;
}
