<div class="container-fluid">

    <div class="row" [formGroup]="form">
        <div class="col-xs-6">
            <div class="form-group">
                <label class="form-grid-item" for="description">Bezeichnung*</label>
                <input type="text" placeholder="Name" class="form-control form-grid-item" formControlName="description" id="description">
                <div class="input-error" *ngIf="form.get('description').hasError('required') && form.get('description').touched">Bezeichnung wird benötigt</div>
            </div>
            <div class="form-group">
                <label class="form-grid-item" for="file">Dateiname*</label>
                <input *ngIf="mode === 'add'" type="file" id="file" [accept]="allowedContentTypes" (change)="file = $event.target['files'][0]">
                <input *ngIf="mode === 'edit'" type="text" class="form-control form-grid-item" formControlName="fileName" id="fileName">
                <div class="input-error" *ngIf="mode === 'edit' && form.get('fileName').hasError('required') && form.get('description').touched">Dateiname wird benötigt</div>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-xs-6">
            <button (click)="canceled.emit()" class="btn btn-default"><i class="fa fa-long-arrow-left"></i>&nbsp;Zurück</button>&nbsp;
            <button (click)="save()" class="btn btn-primary" [disabled]="!form.valid || !form.dirty || (mode === 'add' && !file)"><i class="fa fa-save"></i>&nbsp;Speichern</button>
        </div>
    </div>

</div>
