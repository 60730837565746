import {Directive, EventEmitter, HostListener, Input, OnInit, Output} from '@angular/core';
import {LocalstorageStateService} from '../services/localstorage-state.service';
import {FilterMetadata} from 'primeng/api/filtermetadata';

@Directive({
    selector: '[appFiltersLocalstoragePersistence]'
})
// This directive can be used on p-table components to save and retrieve filter values to and from localstorage
export class FilterLocalstoragePersistenceDirective implements OnInit {
    @Output() filtersChange: EventEmitter<PrimeTableFilter> = new EventEmitter();
    @Input() tableIdentifier: string;
    private keySuffix: string = '_filters';

    constructor(private localstorageStateService: LocalstorageStateService) {
    }

    ngOnInit(): void {
        if (!this.tableIdentifier) {
            throw new Error('@Input \'tableIdentifier\' needs to be set, so that pagination data can be stored in localStorage');
        }
        const [isSet, filtersFromLocalStorage] = this.localstorageStateService.get<PrimeTableFilter>(this.tableIdentifier + this.keySuffix);
        if (isSet) {
            this.filtersChange.emit(filtersFromLocalStorage);
        }

    }

    @HostListener('onFilter', ['$event'])
    onFilter({filters}: PrimeTableFilterEvent) {
        this.localstorageStateService.set<PrimeTableFilter>(this.tableIdentifier + this.keySuffix, filters)
    }
}

export interface PrimeTableFilter {
    [key: string]: FilterMetadata;
}

interface PrimeTableFilterEvent {
    filters: PrimeTableFilter
}
