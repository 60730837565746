import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {Observable} from "rxjs";
import {MyMercatusAccount} from "./my-mercatus-account";

@Injectable({
    providedIn: 'root'
})
export class MyMercatusService {
    baseUrl: string = `${environment.apiUrl}/my-mercatus`;

    constructor(private http: HttpClient) {
    }

    generateAccounts(jobId: string) {
        const query = `${this.baseUrl}/jobs/generate-accounts/${jobId}`;
        return this.http.post(query, "");
    }

    public deleteAccount(id: string) {
        const query = `${this.baseUrl}/accounts/${id}`;
        return this.http.delete(query);
    }

    public invite(contactId: number): Observable<MyMercatusAccount> {
        const query = `${this.baseUrl}/accounts`;
        return this.http.post<MyMercatusAccount>(query, {"contactId": contactId});
    }

    public inviteAgain(myMercatusId: string) {
        const query = `${this.baseUrl}/jobs/account-activations`;
        return this.http.post(query, {"id": myMercatusId});
    }
}
