import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {Angebotsart} from "../../models/angebotsart.model";
import {SelectItem} from "primeng/api";

@Component({
    selector: 'app-angebotsarten-dropdown',
    templateUrl: './angebotsarten-dropdown.component.html',
    styleUrls: ['./angebotsarten-dropdown.component.scss']
})
export class AngebotsartenDropdownComponent implements OnInit, OnChanges {

    @Input()
    public angebotsarten: Angebotsart[];

    @Input()
    public selectedAngebotsartName: string;

    @Output()
    public angebotsartChanged = new EventEmitter<Angebotsart>();

    public angebotsartenItems: SelectItem[];

    constructor() {
    }

    ngOnInit() {
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.angebotsarten && changes.angebotsarten.currentValue.length > 0) {
            this.angebotsartenItems = this.angebotsarten.map((art) => {
                return {label: art.name, value: art.name, icon: art.icon}
            });
        }
    }

    public onDropdownChange(event) {
        this.angebotsartChanged.emit(this.angebotsarten.find(art => art.name === event.value));
    }

}
