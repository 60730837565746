import { Injectable } from '@angular/core';

import { ComponentCanDeactivate } from './componentCanDeactivate';

@Injectable()
export class CanDeactivateGuard  {
    canDeactivate(component: ComponentCanDeactivate): boolean {

        if (!component.canDeactivate()) {
            return confirm('Sie haben ungespeicherte Änderungen. Wenn Sie die Seite verlassen, verlieren Sie Ihre ungespeicherten Änderungen.');
        }
        return true;
    }
}
