import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {Veranstaltungsserie} from '../../shared/models/veranstaltungsSerie.model';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';

@Injectable()
export class VeranstaltungsserienDataService {
    baseUrl: string = environment.apiUrl;

    constructor(private http: HttpClient) {
    }

    loadAllVeranstaltungsserien(): Observable<Veranstaltungsserie[]> {
        const query = `${this.baseUrl}/veranstaltungsserien`;
        return this.http.get<Veranstaltungsserie[]>(query);
    }

    updateVeranstaltungsSerie(veranstaltungsserieToUpdate: Veranstaltungsserie): Observable<Veranstaltungsserie> {
        const query = `${this.baseUrl}/veranstaltungsserien`;
        return this.http.put<Veranstaltungsserie>(query, veranstaltungsserieToUpdate);
    }
}
