<div class="row navigation-bar">
    <nav id="main-menu"
         class="navbar navbar-lg navbar-inverse bg-inverse navbar-fixed-top"
         role="navigation">
        <div class="container-fluid">
            <div class="navbar-header">
                <button type="button"
                        class="navbar-toggle collapsed"
                        data-toggle="collapse"
                        data-target="#navbar"
                        aria-expanded="false"
                        aria-controls="navbar">
                    <span class="sr-only">Toggle navigation</span>
                    <span class="icon-bar"></span>
                    <span class="icon-bar"></span>
                    <span class="icon-bar"></span>
                </button>
                <a class="navbar-brand" routerLink="/dashboard">
                    <img src="../assets/img/mercatus-icon.svg" height="44" alt="Mercatus"/>
                </a>
            </div>
            <div id="navbar"
                 class="navbar-collapse collapse">
                <ul class="nav navbar-nav" *ngFor="let navItem of navItems">
                    <li [class.active]="navItem.active" *ngIf="!navItem.items; else subNavItems">
                        <a class="nav-link {{navItem.title}}" routerLink="{{navItem.link}}"><img [src]="navItem.icon" class="mainnavicon" height="32" alt="navItem.title"/>
                        <span class="hideOnResize">{{navItem.title}}</span>
                    </a>
                    </li>
                    <ng-template #subNavItems>
                        <li [class.active]="navItem.active" class="dropdown">
                            <a href="#"  routerLink="{{navItem.link}}"class="dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false"><img [src]="navItem.icon" class="mainnavicon" height="32"                                                                                                                                      alt="navItem.title"/><span class="hideOnResize">{{navItem.title}}</span><span class="caret"></span></a>
                            <ul class="dropdown-menu">
                                <li role="menu" [class.active]="subItem.active" *ngFor="let subItem of navItem.items" class="{{subItem.itemClass}}">
                                    <a class="dropdown-item nav-link" routerLink="{{subItem.link}}"><i class="{{subItem.iconClass}}"></i>
                                    {{subItem.title}}
                                    </a>
                                </li>
                            </ul>
                        </li>
                    </ng-template>
                </ul>
                <ul class="nav navbar-nav navbar-right">
                    <li>
                        <p class="navbar-text hidden-xs"><img (click)="userNameClick$.next(true)" src="../assets/img/topnav/mercatus-user.svg" class="mainnavicon" height="32" alt="Logged in User: {{userProfile?.email}}"/> <span class="hideOnResize">{{userProfile?.email}}</span></p>
                    </li>
                    <li>
                        <a (click)="logout()" class="logout-element" title="{{userProfile?.email}} abmelden">
                            <img src="../assets/img/topnav/mercatus-logout.svg" class="mainnavicon" height="32" alt="Log Out"/>
                            <span class="navbar-text visible-xs">Logout</span>
                        </a>
                    </li>
                    <li>
                        <a href="https://docs.mercatus.one" target="_blank" title="Hilfe">
                            <img src="../assets/img/topnav/mercatus-help.svg" class="mainnavicon" height="32" alt="Hilfe"/>
                            <span class="navbar-text visible-xs">Hilfe</span>
                        </a>
                    </li>
                </ul>

            </div>
        </div>
    </nav>
</div>
