import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {ReportDefinitions} from '../models/reportdefinitions.model';
import {ReportRecord, ReportRecordTopGroup} from '../models/reportRecord';
import {Observable} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ReportingDataService {
    baseUrl: string = `${environment.apiUrl}`;

    constructor(private http: HttpClient) {
    }

    getReportDefinitions(): Observable<ReportDefinitions> {
        const query = `${this.baseUrl}/reports`;
        return this.http.get<ReportDefinitions>(query);
    }

    getReportPdf(teilnahmeIds: number[], reportId: number, jobId: string): Observable<string> {
        const query = `${this.baseUrl}/reports/${reportId}/pdfpreview/${jobId}`;
        return this.http.post(query, teilnahmeIds, {responseType: 'text'});
    }

    downloadReportExport(teilnahmeIds: number[], reportId: number, fileName: string, actions: string[]): Observable<Blob> {
        const query = `${this.baseUrl}/reports/${reportId}/download`;
        return this.http.post(query,
            {
                "teilnahmeIds": teilnahmeIds,
                "actions": actions,
                "fileName": fileName
            }, {responseType: 'blob'});
    }

    getReportHistoryForKontakt(kontaktId: number): Observable<ReportRecordTopGroup> {
        const query = `${this.baseUrl}/kontakte/${kontaktId}/reporthistory`;
        return this.http.get<ReportRecordTopGroup>(query);
    }

    getReportHistoryForVeranstaltung(veranstaltungId: number): Observable<ReportRecordTopGroup> {
        const query = `${this.baseUrl}/veranstaltungen/${veranstaltungId}/reporthistory`;
        return this.http.get<ReportRecordTopGroup>(query);
    }

    getReportHistoryPdf(filename: string): Observable<Blob> {
        const query = `${this.baseUrl}/reporthistory/${filename}`;
        return this.http.get(query, {responseType: 'blob'});
    }

    uploadNewReport(newReport: any, file: File): Observable<any> {
        const query = `${this.baseUrl}/reports`;
        const formData: FormData = new FormData();
        formData.append('file', file, file.name);
        formData.append('anzeigeName', newReport.name);

        return this.http.post(query, formData);
    }

    replaceReportTemplate(reportId: number, file: File): Observable<any> {
        const query = `${this.baseUrl}/reports/${reportId}/replace-template`;
        const formData: FormData = new FormData();
        formData.append('file', file, file.name);
        return this.http.post(query, formData);
    }

    updateReport(reportForm: any): Observable<any> {
        const query = `${this.baseUrl}/reports`;
        return this.http.put(query, reportForm);
    }

    deleteReport(id: number): Observable<any> {
        const query = `${this.baseUrl}/reports/${id}`;
        return this.http.delete(query, {});
    }

    getOldReportHistoryPdf(filename: string): Observable<ReportRecord[]> {
        const query = `${this.baseUrl}/reporthistory/${filename}/history`;
        return this.http.get<Array<ReportRecord>>(query);
    }

    getReportTemplate(dotxTemplateFilename: string): Observable<Blob> {
        const query = `${this.baseUrl}/reports/${dotxTemplateFilename}/download-template`;
        return this.http.get(query, {responseType: 'blob'});
    }

    getXMLTemplate(): Observable<Blob> {
        const query = `${this.baseUrl}/reports/xmltemplate`;
        return this.http.get(query, {responseType: 'blob'});
    }
}
