import packageInfo from '../../package.json';

export const environment = {
    name: 'staging',
    production: false,
    backendUrl: 'https://api-staging.mercatus.one',
    apiUrl: 'https://api-staging.mercatus.one/api/v1.0',
    VERSION: packageInfo.version,
    maintenanceMode: false,
    keycloak: {
        config: {
            realm: "mercatus",
            url: "https://keycloak-staging.mercatus.one",
            clientId: "mercatus"
        },
        initOptions: {
            onLoad: "check-sso",
            silentCheckSsoRedirectUri: "https://app-staging.mercatus.one/assets/silent-check-sso.html"
        }
    }
};
