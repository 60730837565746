import { Component, Input, OnInit } from '@angular/core';
import { SafeResourceUrl } from "@angular/platform-browser";

@Component({
    selector: 'app-pdf-preview',
    templateUrl: './pdf-preview.component.html',
    styleUrls: ['./pdf-preview.component.scss']
})
export class PdfPreviewComponent implements OnInit {
    @Input() data: SafeResourceUrl;

    constructor() {
    }

    ngOnInit() {
    }

}
