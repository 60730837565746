import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { Router } from '@angular/router';
import { Repraesentation } from '../../models/repraesentation.model';
import {Teilnahme} from '../../models/teilnahme.model';

@Component({
    selector: 'app-old-teilnahmen',
    templateUrl: './old-teilnahmen.component.html',
    styleUrls: ['./old-teilnahmen.component.scss']
})
export class OldTeilnahmenComponent implements OnInit {

    @Input() repraesentation: Repraesentation;
    @Input() maxNoOfFruehereTeilnahmenToShow: number;
    @Output() navigateToStandplatzTriggered: EventEmitter<Teilnahme> = new EventEmitter<Teilnahme>();

    constructor(private router: Router) {
    }

    ngOnInit() {
    }

    navigateToStandplatz(teilnahme: Teilnahme) {
        this.router.navigate([`/veranstaltungen/${teilnahme.veranstaltung.id}/zuordnung/sektor/${teilnahme.standplatz.parzelleSektorId}/standplatz/${teilnahme.standplatz.id}`]);
    }
}
