import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Produkt } from '../../models/produkt.model';
import {ProdukteDataService} from "../../../settings/services/produkte/produkte-data.service";

@Component({
    selector: 'app-produkte-autocomplete',
    templateUrl: './produkte-autocomplete.component.html',
    styleUrls: ['./produkte-autocomplete.component.scss']
})
export class ProdukteAutocompleteComponent implements OnInit {
    @Input() sortiment: Produkt[];
    @Output() productAdded: EventEmitter<Produkt> = new EventEmitter<Produkt>();
    @Output() productRemoved: EventEmitter<Produkt> = new EventEmitter<Produkt>();
    @Output() productCreated: EventEmitter<Produkt> = new EventEmitter<Produkt>();
    @ViewChild('productAutoComplete', { static: true }) productAutoComplete;
    allProducts: Produkt[] = [];
    filteredProductsMultiple: Produkt[] = [];

    constructor(private produkteDataService: ProdukteDataService) {
    }

    ngOnInit() {
       this.loadAllProducts();
    }

    loadAllProducts() {
        this.produkteDataService.getProdukte()
        .subscribe(produkte => {
            this.allProducts = produkte;
        });
    }

    filterProductsMultiple(event) {
        const productsToFilter = this.allProducts.filter(p => this.sortiment.find(s => s.id === p.id) == null)
        this.filteredProductsMultiple = productsToFilter.filter(p => p.name.toLowerCase().startsWith(event.query.toLowerCase()));
    }


    createNewProduct(productName: string) {
        if (productName) {
            this.produkteDataService.createNewProduct(new Produkt(productName))
                .subscribe((product) => {
                    this.productAutoComplete.multiInputEL.nativeElement.value = '';
                    this.productAutoComplete.multiInputEL.nativeElement.strategyName = '';
                    this.productAdded.emit(product);
                    this.loadAllProducts();
                });
        }
    }

    removeProduct(removedProduct: Produkt) {
        this.productRemoved.emit(removedProduct);
    }

    assignExistingProduct(productToAssing: Produkt) {
        this.productAdded.emit(productToAssing);
    }

}
