<div class="row">
    <div class="col-xs-11">
        <span class="p-fluid">
            <p-autoComplete
            #productAutoComplete
            [(ngModel)]="sortiment"
            [suggestions]="filteredProductsMultiple"
            (completeMethod)="filterProductsMultiple($event)"
            styleClass="wid100"
            [minLength]="1"
            placeholder="Produkte"
            field="name"
            [multiple]="true"
            (keyup.enter)="createNewProduct($event.target.value)"
            (onUnselect)="removeProduct($event.value)"
            (onSelect)="assignExistingProduct($event.value)"
            id="productAutoComplete">
            </p-autoComplete>
        </span>
    </div>
    <div class="col-xs-1" style="padding-left: 0;">
        <button
            (click)="createNewProduct(productAutoComplete?.value?.multiInputEL?.nativeElement?.value)"
            [disabled]="!productAutoComplete?.value?.multiInputEL?.nativeElement?.value"
            type="button"
            style="margin-top: 6px;"
            class="btn btn-default btn-xs">
            <i class="fa fa-plus"></i>
        </button>
    </div>
</div>
