import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { Sortiment } from '../models/sortiment.model';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class SortimenteDataService {
    baseUrl: string = `${environment.apiUrl}/sortimente`;

    constructor(private http: HttpClient) {
    }

    assignProduktToRepraesentation(repraesentationId: number, produktId: number): Observable<any> {
        const query = `${this.baseUrl}`;
        return this.http.post(query, new Sortiment(repraesentationId, produktId));
    }

    removeAssignment(repraesentationId: number, produktId: number): Observable<any> {
        const query = `${this.baseUrl}/repraesentation/${repraesentationId}/produkt/${produktId}`;
        return this.http.delete(query);
    }
}
