import {Component, Input, OnInit} from '@angular/core';
import {Veranstaltung} from "../../models/veranstaltung.model";
import {VeranstaltungsserienDataService} from "../../../veranstaltungen/services/veranstaltungsserien-data.service";
import {VeranstaltungCreationSettings} from "../../models/veranstaltungCreationSettings.model";

@Component({
    selector: 'app-serien-icon',
    templateUrl: './serien-icon.component.html',
    styleUrls: ['./serien-icon.component.scss']
})
export class SerienIconComponent implements OnInit {
    @Input() veranstaltung: Veranstaltung;
    @Input() veranstaltungCreationSettings: VeranstaltungCreationSettings;
    showIconSelector: boolean = false;

    iconFileNames = [
        'generalmarket.svg',
        'productmarket.svg',
        'fleamarket.svg',
        'vegetablemarket.svg',
        'eastermarket.svg',
        'summermarket.svg',
        'wintermarket.svg',
        'christmasmarket.svg'
    ];

    constructor(private veranstaltungsserienDataService: VeranstaltungsserienDataService) {
    }

    ngOnInit() {
        this.showIconSelector = false;
    }

    setSerieIcon(fileName: string) {
        if (!this.veranstaltung) {
            this.veranstaltungCreationSettings.iconName = fileName;
            this.showIconSelector = false;
        } else {
            this.veranstaltung.veranstaltungsserie.iconFileName = fileName;
            this.veranstaltungsserienDataService.updateVeranstaltungsSerie(this.veranstaltung.veranstaltungsserie).subscribe(() => {
                this.showIconSelector = false;
            });
        }
    }

    show() {
        this.showIconSelector = true;
    }

}
