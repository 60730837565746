import {Veranstaltung} from './veranstaltung.model';
import {VeranstaltungForCreationModel} from './veranstaltungForCreation.model';

export class VeranstaltungCreationSettings {
    // Step 1
    isNewVeranstaltungsSerie: boolean;
    basedOnVeranstaltungsSerieId: number;
    iconName: string = "generalmarket.svg";

    // Step 2
    veranstaltungName: string;
    ort: string;
    datumVon: Date;
    datumBis: Date;
    hasDublicatedName: boolean;

    // Step 3
    hasNewTeilnehmerList: boolean;
    teilnehmerListBasedOnName: string;
    teilnehmerListBasedOnId: number;

    // Step 4
    hasNewLayout: boolean;
    layoutBasedOnName: string;
    layoutBasedOnId: number;
    copySektoren: boolean;
    copyParzellen: boolean;
    copyStandplaetze: boolean;


    // Step 5
    hasNewLeistungenList: boolean;
    leistungenBasedOnName: string;
    leistungenBasedOnId: number;
    filterTeilnehmer: number;
    includeDeactivatedTeilnahmen: boolean;
    copyNewVat: boolean;

    // Step 6
    hasNewUploads: boolean;
    uploadsBasedOnName: string;
    uploadsBasedOnId: number;

    constructor() {
        this.isNewVeranstaltungsSerie = true;
        this.basedOnVeranstaltungsSerieId = -1;
        this.veranstaltungName = '';
        this.ort = '';
        this.filterTeilnehmer = -1;
        this.includeDeactivatedTeilnahmen = false;

        this.setBasedOnListsToDefault();
    }

    setBasedOnVeranstaltungId(serieId: number, neusteVeranstaltungInSerie?: Veranstaltung) {
        this.basedOnVeranstaltungsSerieId = serieId;

        if (neusteVeranstaltungInSerie) {
            this.ort = neusteVeranstaltungInSerie.ort;

            this.hasNewTeilnehmerList = false;
            this.teilnehmerListBasedOnId = neusteVeranstaltungInSerie.id;
            this.teilnehmerListBasedOnName = neusteVeranstaltungInSerie.name;

            this.hasNewLayout = false;
            this.layoutBasedOnId = neusteVeranstaltungInSerie.id;
            this.layoutBasedOnName = neusteVeranstaltungInSerie.name;

            this.hasNewLeistungenList = false;
            this.leistungenBasedOnId = neusteVeranstaltungInSerie.id;
            this.leistungenBasedOnName = neusteVeranstaltungInSerie.name;

            this.hasNewUploads = false;
            this.uploadsBasedOnId = neusteVeranstaltungInSerie.id;
            this.uploadsBasedOnName = neusteVeranstaltungInSerie.name;
        } else {
            this.setBasedOnListsToDefault();
        }
    }

    private setBasedOnListsToDefault() {
        this.veranstaltungName = '';
        this.ort = '';

        this.hasNewTeilnehmerList = true;
        this.teilnehmerListBasedOnId = -1;
        this.teilnehmerListBasedOnName = '';

        this.hasNewLayout = true;
        this.layoutBasedOnId = -1;
        this.layoutBasedOnName = '';

        this.hasNewLeistungenList = true;
        this.leistungenBasedOnId = -1;
        this.leistungenBasedOnName = '';

        this.hasNewUploads = true;
        this.uploadsBasedOnId = -1;
        this.uploadsBasedOnName = '';
    }

    setTeilnehmerListBasedOnVeranstaltung(basedOn: Veranstaltung) {
        this.teilnehmerListBasedOnId = basedOn.id;
        this.teilnehmerListBasedOnName = basedOn.name;
    }

    setLayoutBasedOnVeranstaltung(basedOn: Veranstaltung) {
        this.layoutBasedOnId = basedOn.id;
        this.layoutBasedOnName = basedOn.name;
    }

    setLeistungenListBasedOnVeranstaltung(basedOn: Veranstaltung) {
        this.leistungenBasedOnId = basedOn.id;
        this.leistungenBasedOnName = basedOn.name;
    }

    setUploadsBasedOnVeranstaltung(basedOn: Veranstaltung) {
        this.uploadsBasedOnId = basedOn.id;
        this.uploadsBasedOnName = basedOn.name;
    }

    getTeilnehmerSummary(): string {
        return this.hasNewTeilnehmerList ? 'Leere Teilnehmerliste' : `Basierend auf ${this.teilnehmerListBasedOnName}`;
    }

    getLayoutSummary(): string {
        return this.hasNewLayout ? 'Neues Standplatzlayout' : `Basierend auf ${this.layoutBasedOnName}`;
    }

    getLeistungenSummary(): string {
        let summary = this.hasNewLeistungenList ? 'Leere Leistungsliste' : `Basierend auf ${this.leistungenBasedOnName}`;
        summary += this.copyNewVat ? ' (MwSt. Sätze von 2024 verwenden)' : '';
        return summary;
    }

    getUploadsSummary(): string {
        return this.hasNewUploads ? 'Keine Uploads' : `Basierend auf ${this.uploadsBasedOnName}`;
    }

    generateCreationObject(): VeranstaltungForCreationModel {
        return {
            isNewVeranstaltungsSerie: this.isNewVeranstaltungsSerie,
            basedOnVeranstaltungsSerieId: this.basedOnVeranstaltungsSerieId,
            veranstaltungName: this.veranstaltungName,
            ort: this.ort,
            datumVon: this.datumVon,
            datumBis: this.datumBis,
            iconFileName: this.iconName,

            hasNewTeilnehmerList: this.hasNewTeilnehmerList,
            filterTeilnehmer: this.filterTeilnehmer,
            includeDeactivatedTeilnahmen: this.includeDeactivatedTeilnahmen,
            teilnehmerListBasedOnId: this.teilnehmerListBasedOnId,
            copySektoren: this.copySektoren,
            copyParzellen: this.copyParzellen,
            copyStandplaetze: this.copyStandplaetze,

            hasNewLayout: this.hasNewLayout,
            layoutBasedOnId: this.layoutBasedOnId,

            hasNewLeistungenList: this.hasNewLeistungenList,
            leistungenBasedOnId: this.leistungenBasedOnId,
            copyNewVat: this.copyNewVat,

            hasNewUploads: this.hasNewUploads,
            uploadsBasedOnId: this.uploadsBasedOnId
        };
    }
}
