import {Injectable} from '@angular/core';
import {ObservableStore} from '@codewithdan/observable-store';
import {Observable, of} from 'rxjs';
import {TenantSettingsDataService} from '../services/tenant-settings-data.service';
import {map, tap} from 'rxjs/operators';
import {SettingsStoreActions} from './settings-store.actions';
import {SettingsStoreState, TenantSettings} from './settings-store.state';

@Injectable({
    providedIn: 'root'
})
export class SettingsStore extends ObservableStore<SettingsStoreState> {

    constructor(private tenantSettingsDataService: TenantSettingsDataService) {
        super({trackStateHistory: true});
    }

    get(): Observable<SettingsStoreState> {
        const state = this.getState();
        if (state && state.settings) {
            return of(state);
        } else {
            return this.reload();
        }
    }

    reload(): Observable<SettingsStoreState> {
        return this.tenantSettingsDataService
            .getSettings()
            .pipe(
                map(res => ({settings: res})),
                tap(res => this.setState(res, SettingsStoreActions.LoadSettingsFromBackend))
            );
    }

    update(settings: TenantSettings): Observable<SettingsStoreState> {
        return this.tenantSettingsDataService
            .updateSettings(settings)
            .pipe(
                map(res => ({settings: res})),
                tap((res) => this.setState(res, SettingsStoreActions.UpdateSettings))
            );
    }

    initialize() {
        return this.tenantSettingsDataService.initializeSettings()
            .pipe(
                tap((res) => this.setState({settings: res}, SettingsStoreActions.InitializeSettings))
            );
    }
}
