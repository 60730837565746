import {Injectable} from "@angular/core";
import {environment} from "../../../environments/environment";
import {UploadRecord} from "../models/uploadRecord.model";
import {Observable} from 'rxjs';
import {HttpClient} from "@angular/common/http";

@Injectable({
    providedIn: 'root'
})
export class UploadsDataService {

    private baseUrl: string = environment.apiUrl;

    constructor(private http: HttpClient) {
    }

    public getUploads(): Observable<UploadRecord[]> {
        const query = `${this.baseUrl}/uploads`;
        return this.http.get<UploadRecord[]>(query);
    }

    public getUploadsForVeranstaltung(veranstaltungId: number): Observable<UploadRecord[]> {
        const query = `${this.baseUrl}/veranstaltungen/${veranstaltungId}/uploads`;
        return this.http.get<UploadRecord[]>(query);
    }

    public getUploadsForKontakt(kontaktId: number): Observable<UploadRecord[]> {
        const query = `${this.baseUrl}/kontakte/${kontaktId}/uploads`;
        return this.http.get<UploadRecord[]>(query);
    }

    public getUploadsForRepresentation(representationId: number): Observable<UploadRecord[]> {
        const query = `${this.baseUrl}/repraesentationen/${representationId}/uploads`;
        return this.http.get<UploadRecord[]>(query);
    }

    public addUpload(description: string, file: File): Observable<UploadRecord> {
        const query = `${this.baseUrl}/uploads`;
        return this.addUploadWithQuery(query, description, file);
    }

    public addUploadToVeranstaltung(veranstaltungId: number, description: string, file: File): Observable<UploadRecord> {
        const query = `${this.baseUrl}/veranstaltungen/${veranstaltungId}/uploads`;
        return this.addUploadWithQuery(query, description, file);
    }

    public addUploadToKontakt(kontaktId: number, description: string, file: File): Observable<UploadRecord> {
        const query = `${this.baseUrl}/kontakte/${kontaktId}/uploads`;
        return this.addUploadWithQuery(query, description, file);
    }

    public addUploadToRepresentation(representationId: number, description: string, file: File): Observable<UploadRecord> {
        const query = `${this.baseUrl}/repraesentationen/${representationId}/uploads`;
        return this.addUploadWithQuery(query, description, file);
    }

    public getUpload(uploadRecord: UploadRecord): Observable<Blob> {
        const query = `${this.baseUrl}/uploads/${uploadRecord.id}`;
        return this.http.get(query, {responseType: 'blob'});
    }

    public updateUpload(uploadRecord: UploadRecord): Observable<UploadRecord> {
        const query = `${this.baseUrl}/uploads/${uploadRecord.id}`;
        return this.http.put<UploadRecord>(query, uploadRecord);
    }

    public deleteUpload(fileId: number): Observable<Object> {
        const query = `${this.baseUrl}/uploads/${fileId}`;
        return this.http.delete(query)
    }

    private addUploadWithQuery(query: string, description: string, file: File): Observable<UploadRecord> {
        const formData: FormData = new FormData();
        formData.append('file', file, file.name);
        formData.append('description', description);
        return this.http.post<UploadRecord>(query, formData);
    }
}
