import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'truncate'
})
export class TruncatePipe implements PipeTransform {
    transform(value: any, maxLength?: number, ending?: string): any {
        ending = ending || '...';
        let truncatedText = '';
        if (value) {
            truncatedText = value.length > maxLength ? value.substring(0, maxLength) + ending : value;
        }
        return truncatedText;
    }
}
